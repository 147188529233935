import { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import '@bluecodecom/fonts';

import { LoaderProvider } from '@bluecodecom/loader';

import { ThemeProvider } from '@bluecodecom/theme';

import { initI18n } from '@bluecodecom/i18n';

declare global {
  interface Window {
    jwt?: string;
  }
}

initI18n(
  { de: {}, en: {}, it: {} },
  {
    interpolation: {
      escapeValue: false,
    },
  },
);

const App = lazy(() => import('./app'));

createRoot(document.getElementById('root') as HTMLElement).render(
  <ThemeProvider observe>
    <LoaderProvider>
      <BrowserRouter>
        <Suspense>
          <App />
        </Suspense>
      </BrowserRouter>
    </LoaderProvider>
  </ThemeProvider>,
);
